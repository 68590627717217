import { PrismicRichText } from "@prismicio/react";
import { Button, ImageTextCard, ScaledHeading } from "@/components";
import { PrismicNextImage } from "@prismicio/next";
import { Parallax } from "react-scroll-parallax";

import Styles from "./FeaturedService.module.css";

/**
 * @typedef {import("@prismicio/client").Content.FeaturedServiceSlice} FeaturedServiceSlice
 * @typedef {import("@prismicio/react").SliceComponentProps<FeaturedServiceSlice>} FeaturedServiceProps
 * @param {FeaturedServiceProps}
 */
const FeaturedService = ({ slice: { primary, items } }) => {
  return (
    <section className={Styles.wrapper}>
      <div className={Styles.serviceContainer}>
        <ScaledHeading
          heading={primary?.section_heading}
          containerClass={Styles.scaledContainer}
        />
        <div className={Styles.contentContainer}>
          <div className={Styles.primaryContent}>
            <h2>{primary?.primary_heading}</h2>
            <PrismicRichText field={primary?.primary_description} />
            {primary?.primary_cta_link && primary?.primary_cta_label && (
              <Button
                link={primary?.primary_cta_link}
                label={primary?.primary_cta_label}
              />
            )}
          </div>
          <div className={Styles.secondaryContent}>
            <h2>{primary?.secondary_heading}</h2>
            <PrismicRichText field={primary?.secondary_description} />
            {primary?.secondary_cta_link && primary?.secondary_cta_label && (
              <Button
                link={primary?.secondary_cta_link}
                label={primary?.secondary_cta_label}
              />
            )}
          </div>
        </div>
        <PrismicNextImage
          field={primary?.background_image}
          className={Styles.backgroundImage}
        />
        <Parallax translateX={[-20, 20]} className={Styles.scrollingImage}>
          <PrismicNextImage
            field={primary?.scrolling_image}
            className={Styles.scrollingImage}
          />
        </Parallax>
      </div>

      <ImageTextCard
        image={primary?.featured_product_image}
        heading={primary?.featured_product_title}
        description={primary?.featured_product_description}
        ctaLink={primary?.featured_product_cta_link}
        ctaLabel={primary?.featured_product_cta_label}
        negativeMargin={true}
      />
      {!primary?.remove_card_section && (
        <div className={Styles.cardsContainer}>
          <h4 className={Styles.cardsHeading}>{primary?.cards_heading}</h4>
          <div className={Styles.cards}>
            {items.map((item, index) => (
              <div className={Styles.card} key={index}>
                {/* <div className={Styles.cardNumber}>{index + 1}</div> */}
                <div className={Styles.starContainer}>
                  {Array(5)
                    .fill()
                    .map((_, index) => (
                      <div className={Styles.star} key={index}></div>
                    ))}
                </div>
                <h4 className={Styles.cardHeading}>{item?.card_heading} </h4>

                <p className={Styles.cardSubheading}>{item?.card_subheading}</p>
                <PrismicRichText field={item?.card_description} />
              </div>
            ))}
          </div>
        </div>
      )}
    </section>
  );
};

export default FeaturedService;
